import { useFormik } from "formik";
import { useSelector } from "react-redux";
import addExternalServiceValidation from "../utils/validation";
import { handleCreateExternalService } from "../utils/createExternalService";

export function useExternalServiceForm(closeModal) {
  const { activeBranch } = useSelector(state => state.app);

  const initialValues = {
    service_type_id: null,
    time: null,
    eta: null,
    system_service_description: "",
    branch_id: parseInt(activeBranch?.id)
  };

  const formik = useFormik({
    initialValues,
    validate: addExternalServiceValidation,
    onSubmit: values => handleCreateExternalService(values, closeModal)
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting
  } = formik;

  function handleChangeDate(name, date) {
    setFieldValue(name, date);
  }

  function handleChangeTime(name, time) {
    if (!time) return;

    const currentDate = values[name] ? new Date(values[name]) : new Date();

    currentDate.setHours(time.getHours());
    currentDate.setMinutes(time.getMinutes());
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    setFieldValue(name, currentDate);
  }

  return {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleChangeDate,
    handleChangeTime,
    isSubmitting
  };
}
