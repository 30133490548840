import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";

// Components
import {
  Header,
  Body,
  Button,
  Search,
  CentralModal,
  Card
} from "../../../../UI";
import { Heading2, Paragraph } from "../../../../typography";
import StaffManagementItem from "../../../../lists/generalBranchSettings/StaffManagement.item";

// Containers
import AddStaff from "../../../../../containers/forms/generalBranchSettingsForms/StaffManagement/AddStaff/AddStaff";
import EditStaff from "../../../../../containers/forms/generalBranchSettingsForms/StaffManagement/EditStaff/EditStaff";

// Constants
import * as modalTypes from "../../../../../constants/staffManagement.constants";

// Builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";
import { StaffManagementTableHeadBuilder } from "../../../../../builders/generalBranchSettings.builder";

// Calls
import {
  fetchRolesRequest,
  fetchStaffMembersRequest,
  deleteStaffMemberRequest
} from "../../../../../api/calls/general-branch-settings.call";

// Utils
import { isEmpty } from "../../../../../utils";

// Styles
import { StaffManagementStyles } from "./staffManagement.styles";

const StaffManagement = ({ branchId }) => {
  const [staffManagmentSectionState, setStaffManagmentSectionState] = useState({
    modalType: undefined,
    chosenStaffMember: undefined,
    staffMembers: [],
    roles: [],
    searchQuery: ""
  });

  const fetchStaffMemebersAndRoles = useCallback(async () => {
    const rolesAndStaffMembersResponse = await Promise.all([
      fetchRolesRequest(branchId),
      fetchStaffMembersRequest(branchId)
    ]);

    const rolesResponse = rolesAndStaffMembersResponse[0];
    const staffMembersResponse = rolesAndStaffMembersResponse[1];
    if (rolesResponse.hasError) {
      toast.error(rolesResponse.error.message);
      return;
    }

    if (staffMembersResponse.hasError) {
      toast.error(staffMembersResponse.error.message);
      return;
    }
    setStaffManagmentSectionState(prevValues => ({
      ...prevValues,
      staffMembers: staffMembersResponse.staffMembers,
      roles: rolesResponse.roles.map(role => {
        return { ...role, id: parseInt(role.id) };
      })
    }));
  }, [branchId]);

  useEffect(() => {
    fetchStaffMemebersAndRoles();
  }, [fetchStaffMemebersAndRoles]);

  const deleteStaffMember = async id => {
    const result = await swal({
      title: "Delete Staff Member?",
      text: "Are you sure you want to delete this member?",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }

    const response = await deleteStaffMemberRequest(branchId, id);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Staff member successfully deleted.");
    setStaffManagmentSectionState(prevValues => ({
      ...prevValues,
      staffMembers: response.staffMembers
    }));
  };

  const addStaffMemberOpenModal = () => {
    setStaffManagmentSectionState(prevValues => ({
      ...prevValues,
      modalType: modalTypes.ADD_STAFF
    }));
  };

  const editStaffMemberOpenModal = member => {
    setStaffManagmentSectionState(prevValues => ({
      ...prevValues,
      modalType: modalTypes.EDIT_STAFF,
      chosenStaffMember: member
    }));
  };

  const closeModal = () => {
    setStaffManagmentSectionState(prevValues => ({
      ...prevValues,
      modalType: undefined,
      chosenStaffMember: undefined
    }));
  };

  const setStaffMembersAfterCreateOrEdit = staffMembers => {
    setStaffManagmentSectionState(prevValues => ({
      ...prevValues,
      staffMembers,
      modalType: undefined,
      chosenStaffMember: undefined
    }));
  };

  const handleSearch = e => {
    setStaffManagmentSectionState(prevValues => ({
      ...prevValues,
      searchQuery: e.target.value
    }));
  };

  const filterStaff = query => {
    return staffManagmentSectionState.staffMembers.filter(
      staff => staff.firstName.includes(query) || staff.lastName.includes(query)
    );
  };

  return (
    <StaffManagementStyles>
      <Header className="header">
        <Heading2>STAFF MANAGEMENT</Heading2>
        <div className="searchButtonContainer">
          <div className="searchContainer">
            <Search
              placeholder="Search members"
              value={staffManagmentSectionState.searchQuery}
              onChange={handleSearch}
            />
          </div>
          <Button
            onClick={addStaffMemberOpenModal}
            className="addStaff"
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            Add Staff
          </Button>
        </div>
      </Header>
      {!isEmpty(staffManagmentSectionState.staffMembers) && (
        <>
          <Header className="tableHeader">
            <Paragraph className="listSubtitle">List Of Staff</Paragraph>
            <div className="tableHead">
              {StaffManagementTableHeadBuilder.map(
                ({ title, className }, i) => (
                  <div className={className} key={i}>
                    <Paragraph className="head">{title}</Paragraph>
                  </div>
                )
              )}
            </div>
          </Header>
          <Body className="body">
            {filterStaff(staffManagmentSectionState.searchQuery).map(staff => (
              <StaffManagementItem
                key={staff.id}
                {...staff}
                deleteAction={() => deleteStaffMember(staff.id)}
                editAction={() => editStaffMemberOpenModal(staff)}
              />
            ))}
          </Body>
        </>
      )}
      {staffManagmentSectionState.modalType && (
        <CentralModal closeModal={closeModal} className="modalCard">
          <Card>
            {staffManagmentSectionState.modalType === modalTypes.ADD_STAFF && (
              <AddStaff
                setStaffMembersAfterCreateOrEdit={
                  setStaffMembersAfterCreateOrEdit
                }
                branchId={branchId}
                roles={staffManagmentSectionState.roles}
                closeModal={closeModal}
              />
            )}
            {staffManagmentSectionState.modalType === modalTypes.EDIT_STAFF && (
              <EditStaff
                setStaffMembersAfterCreateOrEdit={
                  setStaffMembersAfterCreateOrEdit
                }
                chosenStaffMember={staffManagmentSectionState.chosenStaffMember}
                branchId={branchId}
                roles={staffManagmentSectionState.roles}
                closeModal={closeModal}
              />
            )}
          </Card>
        </CentralModal>
      )}
    </StaffManagementStyles>
  );
};

export default StaffManagement;
