import { useSelector } from "react-redux";

function createOption(serviceType) {
  return {
    value: serviceType.id,
    label: serviceType.name,
    id: serviceType.id,
    name: serviceType.name
  };
}

export default function useDropdownOptions() {
  const allServiceTypes = useSelector(
    state => state.app.systemComponents.serviceTypes
  );

  const branchServiceTypes = useSelector(
    state => state.app.activeBranch.branchServiceTypes
  );

  if (!branchServiceTypes || !allServiceTypes) {
    return [];
  }

  const dropdownOptions = branchServiceTypes
    .map(item => {
      const serviceType = allServiceTypes.find(
        type => type.id === item.serviceTypeId
      );
      return serviceType ? createOption(serviceType) : null;
    })
    .filter(option => option !== null);

  return dropdownOptions;
}
