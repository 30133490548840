import { toast } from "react-toastify";
import { apiService } from "../../../../../api/api.service";
import { formatDate } from "./timeDate";

async function addExternalService(values) {
  try {
    const serviceDetails = {
      ...values,
      branch_id: values.branch_id,
      time: formatDate(values.time),
      eta: formatDate(values.eta)
    };

    const response = await apiService.post(
      "/services/external",
      serviceDetails
    );

    if (response.hasError) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    console.error("Failed to add external service:", error);

    throw error;
  }
}

export async function handleCreateExternalService(values, closeModal) {
  try {
    await addExternalService(values);
    toast.success("The external service has been successfully added.");
    closeModal();
  } catch (error) {
    console.error("Submit failed:", error);
    toast.error("Failed to create the external service.");
  }
}
