import { useState, useEffect, useCallback } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";

// Components
import RoleItem from "../../../../lists/generalBranchSettings/Role.item";
import { Header, Button, Body, CentralModal, Card } from "../../../../UI";
import { Heading2, Paragraph } from "../../../../typography";

// Containers
import AddRole from "../../../../../containers/forms/generalBranchSettingsForms/RolesAndPermissions/AddRole/AddRole";
import EditRole from "../../../../../containers/forms/generalBranchSettingsForms/RolesAndPermissions/EditRole/EditRole";

// Builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";

// Constants
import * as modalTypes from "../../../../../constants/roles.constants";

// Calls
import {
  fetchRolesRequest,
  deleteRoleRequest
} from "../../../../../api/calls/general-branch-settings.call";
import { fetchPermissionsRequest } from "../../../../../api/calls/permissions.call";

// Utils
import { isEmpty } from "../../../../../utils";

// Styles
import { RolesAndPermissionsStyles } from "./rolesAndPermissions.styles";

const RolesAndPermissions = ({ branchId }) => {
  const [rolesSectionState, setRolesSectionState] = useState({
    roles: [],
    permissions: [],
    chosenRole: undefined,
    modalType: undefined
  });

  const fetchRolesAndPermissions = useCallback(async () => {
    const rolesAndPermissionsResponse = await Promise.all([
      fetchRolesRequest(branchId),
      fetchPermissionsRequest(["all", "staff"])
    ]);
    const rolesResponse = rolesAndPermissionsResponse[0];
    const permissionsResponse = rolesAndPermissionsResponse[1];
    if (rolesResponse.hasError) {
      toast.error(rolesResponse.error.message);
      return;
    }

    if (permissionsResponse.hasError) {
      toast.error(permissionsResponse.error.message);
      return;
    }
    setRolesSectionState(prevValues => ({
      ...prevValues,
      permissions: permissionsResponse.permissions.map(permission => {
        return { ...permission, id: parseInt(permission.id) };
      }),
      roles: rolesResponse.roles
    }));
  }, [branchId]);

  useEffect(() => {
    fetchRolesAndPermissions();
  }, [fetchRolesAndPermissions]);

  const deleteRole = async id => {
    const result = await swal({
      title: "Delete Role?",
      text: "Are you sure you want to delete this role?",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }

    const response = await deleteRoleRequest(branchId, id);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Role successfully deleted.");
    setRolesSectionState(prevValues => ({
      ...prevValues,
      roles: response.roles
    }));
  };

  const addRoleModalOpen = () => {
    setRolesSectionState(prevValues => ({
      ...prevValues,
      modalType: modalTypes.ADD_ROLE
    }));
  };

  const editRoleModalOpen = role => {
    setRolesSectionState(prevValues => ({
      ...prevValues,
      chosenRole: role,
      modalType: modalTypes.EDIT_ROLE
    }));
  };

  const closeModal = () => {
    setRolesSectionState(prevValues => ({
      ...prevValues,
      chosenRole: undefined,
      modalType: undefined
    }));
  };

  const setRolesAfterCreateAndUpdateRole = roles => {
    setRolesSectionState(prevValues => ({
      ...prevValues,
      roles,
      chosenRole: undefined,
      modalType: undefined
    }));
  };

  return (
    <RolesAndPermissionsStyles>
      <Header className="header">
        <Heading2>ROLES & PERMISSIONS</Heading2>
        <Button
          onClick={addRoleModalOpen}
          className="addRole"
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Add New Role
        </Button>
      </Header>
      {!isEmpty(rolesSectionState.roles) && (
        <>
          <Header>
            <Paragraph className="listSubtitle">List Of Staff</Paragraph>
          </Header>
          <Body className="body">
            {rolesSectionState.roles.map((data, i) => (
              <RoleItem
                key={i}
                {...data}
                deleteAction={() => deleteRole(data.id)}
                editAction={() => editRoleModalOpen(data)}
              />
            ))}
          </Body>
        </>
      )}
      {rolesSectionState.modalType && (
        <CentralModal closeModal={closeModal} className="modalCard">
          <Card>
            {rolesSectionState.modalType === modalTypes.ADD_ROLE && (
              <AddRole
                setRolesAfterCreateAndUpdateRole={
                  setRolesAfterCreateAndUpdateRole
                }
                branchId={branchId}
                permissions={rolesSectionState.permissions}
                closeModal={closeModal}
              />
            )}
            {rolesSectionState.modalType === modalTypes.EDIT_ROLE && (
              <EditRole
                setRolesAfterCreateAndUpdateRole={
                  setRolesAfterCreateAndUpdateRole
                }
                chosenRole={rolesSectionState.chosenRole}
                branchId={branchId}
                permissions={rolesSectionState.permissions}
                closeModal={closeModal}
              />
            )}
          </Card>
        </CentralModal>
      )}
    </RolesAndPermissionsStyles>
  );
};

export default RolesAndPermissions;
