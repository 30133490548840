export default function addExternalServiceValidation({
  service_type_id,
  time,
  eta,
  system_service_description
}) {
  const errors = {};

  if (!service_type_id) {
    errors.service_type_id = "* Required";
  }

  if (!time) {
    errors.time = "* Required";
  }

  if (!eta) {
    errors.eta = "* Required";
  }

  if (!system_service_description) {
    errors.system_service_description = "* Required";
  }

  return errors;
}
