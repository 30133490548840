import { useFormik } from "formik";
import { toast } from "react-toastify";

// Components
import { Body, Header } from "../../../../../components/UI";
import { Heading2 } from "../../../../../components/typography";

// Containers
import StaffForm from "../StaffForm/StaffForm";

// Icons
import { Close } from "../../../../../SVGS";

// Form Models
import { StaffMemberFormModel } from "../../../../../models/app/forms";

// Calls
import { updateStaffMemberRequest } from "../../../../../api/calls/general-branch-settings.call";

// Services
import { staffValidate } from "../../../../../services/validations/staffValidation";

// Styles
import { EditStaffStyles } from "./editStaff.styles";

const EditStaff = ({
  closeModal,
  chosenStaffMember,
  branchId,
  roles,
  setStaffMembersAfterCreateOrEdit
}) => {
  const initialValues = new StaffMemberFormModel(
    chosenStaffMember.generateStaffMemeberForm()
  );
  const updateStaffHandler = async values => {
    const response = await updateStaffMemberRequest(
      branchId,
      values,
      initialValues,
      chosenStaffMember.id
    );
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Staff member successfully updated.");
    setStaffMembersAfterCreateOrEdit(response.staffMembers);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: updateStaffHandler,
    validate: staffValidate
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditStaffStyles>
      <Header className="formHeader">
        <Heading2>EDIT STAFF</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <StaffForm
            roles={roles}
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            buttonText="Save Changes"
          />
        </form>
      </Body>
    </EditStaffStyles>
  );
};

export default EditStaff;
