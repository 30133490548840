import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useFormik } from "formik";

// Components
import { Heading2, Label, Paragraph } from "../typography";
import {
  Card,
  Header,
  StatusPill,
  Button,
  Body,
  Footer,
  Map,
  CentralModal,
  NotificationsCounter
} from "../UI";
// Components
import HeaderActions from "./HeaderActions";
import WorkerItem from "../lists/workers/Worker.item";
import ServiceComment from "../lists/serviceComments/ServiceComment.item";

// Builders
import * as buttonDesignType from "../../builders/buttonDesign.types";

// Constants
import * as statusTypes from "../../constants/status.constants";
import * as tiketDetailsModalTypeConsts from "../../constants/tiket-details.constants";

// Containers
import BookedForForm from "../../containers/forms/bookedFor/booked-for.form";
import EtaForm from "../../containers/forms/eta/Eta.form";

// Icons
import { Close, DarkPin, PhoneIcon } from "../../SVGS";

// Actions
import {
  approveServiceRequests,
  openDeclineReason,
  setTiketDetailsModalType,
  updateServiceRequest
} from "../../redux/actions/service-requests.actions";
import {
  cancelService,
  startService,
  pauseService,
  resumeService,
  finishService,
  openWorkersForm,
  setServicesTiketDetailsModalType,
  updateService
} from "../../redux/actions/services.actions";
import {
  getServiceComments,
  deleteComment,
  createServiceComment
} from "../../redux/actions/service-comments.actions";

// Utils
import { isEmpty, enableScroll } from "../../utils";
import {
  convertTimeStampToRequestFormat,
  convertTimeStampToTime,
  convertTimeStampToDateWithSlash,
  convertTimeStampToRequestDate
} from "../../utils/timeStampToDate";

// styles
import { TiketDetailsStyles } from "./tiketDetails.styles";
import TextFieldWithButton from "../UI/TextField/TextFieldWithButton";
import { fetchBranchRequest } from "../../api/calls/branch.call";
import { BranchFormModel } from "../../models/app/forms";
import useSupportRole from "../../hooks/useSupportRole";

const TiketDetails = ({
  closeModal,
  status,
  toggleChat,
  serviceDetails,
  tiketDetailsModalType,
  shouldShowWokers = false
}) => {
  const { user } = useSelector(state => state.app);
  const location = useLocation();
  const dispatch = useDispatch();
  const { systemComponents } = useSelector(state => state.app);
  const { comments: serviceComments } = useSelector(
    state => state.serviceCommentsReducer
  );
  const [branch, setBranch] = useState();
  const { isSupportRole } = useSupportRole();
  const isExternalService = serviceDetails.status === "external";

  const serviceType = systemComponents.serviceTypes.find(
    type => type.typeKey === serviceDetails?.serviceTypeKey
  );

  const isBookedActionVisible =
    serviceDetails?.status === statusTypes.PENDING ||
    serviceDetails?.status === undefined;

  const isVisible =
    serviceDetails?.status === statusTypes.IN_PROGRESS ||
    serviceDetails?.status === statusTypes.PAUSED ||
    serviceDetails?.status === statusTypes.ACCEPTED ||
    serviceDetails?.status === statusTypes.PENDING ||
    serviceDetails?.status === undefined;

  const shouldShowComments = ![statusTypes.EXTERNAL].includes(
    serviceDetails?.status
  );

  const approveAction = () => {
    dispatch(approveServiceRequests(serviceDetails.id));
  };

  const declineAction = () => {
    dispatch(openDeclineReason(serviceDetails.id));
  };

  const changeBookedForHandler = dateObject => {
    const timeStamp = Date.parse(dateObject) / 1000;
    const formatedDate = convertTimeStampToRequestDate(timeStamp);
    const formatedTime = convertTimeStampToRequestFormat(timeStamp);

    if (isEmpty(formatedDate) && isEmpty(formatedTime)) {
      return;
    }
    const payload = {
      time: `${formatedDate} ${formatedTime}`
    };

    dispatch(updateServiceRequest(serviceDetails.id, payload));
  };

  const changeETAHandler = dateObject => {
    const timeStamp = Date.parse(dateObject) / 1000;
    const formatedDate = convertTimeStampToRequestDate(timeStamp);
    const formatedTime = convertTimeStampToRequestFormat(timeStamp);
    if (isEmpty(formatedDate) && isEmpty(formatedTime)) {
      return;
    }
    const payload = {
      eta: `${formatedDate} ${formatedTime}`
    };

    if (
      location.pathname === "/services" ||
      location.pathname === "/employee-schedule"
    ) {
      enableScroll();
      dispatch(updateService(payload, serviceDetails.id));
      return;
    }

    dispatch(updateServiceRequest(serviceDetails.id, payload));
  };

  const cancelServiceAction = () => {
    enableScroll();
    dispatch(cancelService(serviceDetails.id));
  };

  const startServiceAction = () => {
    enableScroll();
    dispatch(startService(serviceDetails.id));
  };

  const pauseServiceAction = () => {
    enableScroll();
    dispatch(pauseService(serviceDetails.id));
  };

  const resumeServiceAction = () => {
    enableScroll();
    dispatch(resumeService(serviceDetails.id));
  };

  const finishServiceAction = () => {
    enableScroll();
    dispatch(finishService(serviceDetails.id));
  };

  useEffect(() => {
    dispatch(
      getServiceComments({
        serviceId: serviceDetails.id
      })
    );
  }, [dispatch, serviceDetails.id]);

  const fetchBranch = useCallback(async () => {
    const response = await fetchBranchRequest(serviceDetails.branchId);
    if (response.hasError) {
    }
    setBranch(new BranchFormModel(response.branch.generateBranchForm()));
  }, [serviceDetails.branchId]);

  useEffect(() => {
    if (isSupportRole) {
      fetchBranch();
    }
  }, [fetchBranch, isSupportRole]);

  const removeComment = async serviceComment => {
    const result = await swal({
      title: "Delete comment?",
      text: "Are you sure you want to delete this comment? This action cannot be undone.",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }

    if (user.id !== serviceComment.user.id) {
      toast.error(`You can't delete other users comments.`);
      return;
    }

    dispatch(deleteComment(serviceComment.id));

    // dispatch(
    //   getServiceComments({
    //     serviceId: serviceDetails.id
    //   })
    // );
  };

  const createServiceCommentHandler = () => {
    dispatch(
      createServiceComment(serviceDetails.id, {
        comment: formik.values.comment
      })
    );
    formik.values.comment = "";
  };

  const formik = useFormik({
    initialValues: { comment: "" }
  });

  const { values, errors, touched, handleBlur, handleChange } = formik;

  return (
    <>
      {serviceDetails && (
        <TiketDetailsStyles>
          <Card className="tiketDetailsCard">
            <Header className="topHeader">
              <div className="headerTopContent">
                <div className="titleAndPill">
                  <Heading2>TICKET DETAILS</Heading2>
                  {serviceDetails.status && (
                    <StatusPill
                      className="pill"
                      status={serviceDetails.status}
                    />
                  )}
                </div>
                <Close onClick={closeModal} />
              </div>
              {isSupportRole && (
                <div className="branch-details">
                  <h3 className="branchName">{branch?.name}</h3>
                  <div className="iconAndText">
                    <DarkPin />
                    <Paragraph className="text">{branch?.address}</Paragraph>
                  </div>
                  <div className="iconAndText">
                    <PhoneIcon />
                    <Paragraph className="text">
                      {branch?.contactNumber}
                    </Paragraph>
                  </div>
                </div>
              )}
              {!isExternalService && (
                <div>
                  <Label>Ticket Action</Label>
                  <HeaderActions
                    toggleChat={toggleChat}
                    status={serviceDetails.status}
                    approveAction={approveAction}
                    declineAction={declineAction}
                    cancelServiceAction={cancelServiceAction}
                    startServiceAction={startServiceAction}
                    pauseServiceAction={pauseServiceAction}
                    resumeServiceAction={resumeServiceAction}
                    finishServiceAction={finishServiceAction}
                  />
                </div>
              )}
            </Header>
            {isVisible && (
              <Header style={{ padding: "12px 24px" }}>
                <Button
                  className="messagesButton"
                  onClick={toggleChat}
                  buttonDesignType={buttonDesignType.SECONDARY}
                >
                  Messages
                  {serviceDetails.newMessagesCount > 0 && (
                    <NotificationsCounter
                      className="notificationCounter"
                      counter={serviceDetails.newMessagesCount}
                    />
                  )}
                </Button>
              </Header>
            )}

            <Body className="tiketDetailsBody">
              <div className="cardContainer">
                <Label>Booked For</Label>
                <div className="cardItem">
                  <div>
                    <Paragraph className="text">
                      {convertTimeStampToTime(serviceDetails.time)}
                    </Paragraph>
                    <Paragraph className="text" style={{ color: "#18181899" }}>
                      {convertTimeStampToDateWithSlash(serviceDetails.time)}
                    </Paragraph>
                  </div>
                  {isBookedActionVisible && (
                    <div>
                      <Button
                        onClick={() =>
                          dispatch(
                            setTiketDetailsModalType(
                              tiketDetailsModalTypeConsts.BOOKED_FOR_FORM
                            )
                          )
                        }
                        className="estimateButton"
                        buttonDesignType={buttonDesignType.SECONDARY}
                      >
                        Change Time
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="cardContainer">
                <Label>ETA</Label>
                <div className="cardItem">
                  <div>
                    <Paragraph className="text">
                      {convertTimeStampToTime(serviceDetails.eta)}
                    </Paragraph>
                    <Paragraph className="text" style={{ color: "#18181899" }}>
                      {convertTimeStampToDateWithSlash(serviceDetails.eta)}
                    </Paragraph>
                  </div>
                  {isVisible && (
                    <div>
                      <Button
                        className="estimateButton"
                        buttonDesignType={buttonDesignType.SECONDARY}
                        onClick={() => {
                          if (
                            location.pathname === "/services" ||
                            location.pathname === "/employee-schedule"
                          ) {
                            dispatch(
                              setServicesTiketDetailsModalType(
                                tiketDetailsModalTypeConsts.ETA_FORM
                              )
                            );
                            return;
                          }
                          dispatch(
                            setTiketDetailsModalType(
                              tiketDetailsModalTypeConsts.ETA_FORM
                            )
                          );
                        }}
                      >
                        Estimate
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="cardContainer">
                <Label>User</Label>
                <div className="cardItem">
                  <div>
                    <Paragraph className="boldText">
                      {isExternalService
                        ? "External user"
                        : `${serviceDetails?.driver?.firstName} ${serviceDetails?.driver?.lastName}`}
                    </Paragraph>
                    <Paragraph className="text" style={{ color: "#18181899" }}>
                      Contact : {serviceDetails?.phoneNumber}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </Body>

            <Body className="tiketDetailsBody">
              {!isEmpty(serviceType) && !isEmpty(serviceDetails.mobility) && (
                <div className="cardContainer">
                  <Label>Service Type</Label>
                  <div className="cardItem">
                    <div>
                      <Paragraph className="boldText">
                        {serviceType.name}
                      </Paragraph>
                      <Paragraph
                        className="text"
                        style={{
                          color: "#18181899",
                          textTransform: "capitalize"
                        }}
                      >
                        {serviceDetails.mobility}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              )}
              {!isEmpty(serviceDetails.truck) && (
                <div className="cardContainer">
                  <Label>Truck</Label>
                  <div className="cardItem">
                    <div>
                      <Paragraph className="boldText">
                        {serviceDetails.truck.manufacturer.manufacturer}
                      </Paragraph>
                      <Paragraph
                        className="text"
                        style={{ color: "#18181899" }}
                      >
                        {serviceDetails.truck.model}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              )}
              {!isEmpty(serviceDetails.trailer) && (
                <div className="cardContainer">
                  <Label>Trailer</Label>
                  <div className="cardItem">
                    <div>
                      <Paragraph className="boldText">
                        {serviceDetails.trailer.manufacturer.manufacturer}
                      </Paragraph>
                      <Paragraph
                        className="text"
                        style={{ color: "#18181899" }}
                      >
                        {serviceDetails.trailer.type.name}
                      </Paragraph>
                    </div>
                    <div>
                      <Paragraph
                        className="text"
                        style={{ textAlign: "right", color: "#18181899" }}
                      >
                        Year : {serviceDetails.trailer.productionYear}
                      </Paragraph>
                      <Paragraph
                        className="text"
                        style={{ textAlign: "right", color: "#18181899" }}
                      >
                        VIN : {serviceDetails.trailer.last4Vin}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              )}
              {isExternalService && (
                <div className="cardContainer">
                  <Label>Description</Label>
                  <div className="cardItem cardItem--description">
                    <div>
                      <Paragraph
                        className="text"
                        style={{ color: "#18181899" }}
                      >
                        {serviceDetails.system_service_description}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              )}
            </Body>
            {!isEmpty(serviceDetails.location) && (
              <Body className="tiketDetailsBody">
                <div className="cardContainer">
                  <Label>Location</Label>
                  <div className="mapContainer">
                    <Button
                      className="copyLocation"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          serviceDetails.location.address
                        );
                        toast.success("Location Copied.");
                      }}
                    >
                      Copy Location
                    </Button>
                    <Map
                      center={{
                        lat: serviceDetails.location.latitude,
                        lng: serviceDetails.location.longitude
                      }}
                    />
                  </div>
                </div>
                <div className="cardContainer">
                  <Label>Address</Label>
                  <div className="cardItem">
                    <div>
                      <Paragraph className="boldText">
                        {serviceDetails.location.address}
                      </Paragraph>
                      <Paragraph
                        className="text"
                        style={{ color: "#18181899" }}
                      >
                        {serviceDetails.location.state},{" "}
                        {serviceDetails.location.city}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </Body>
            )}
            {!isEmpty(serviceDetails.specialRequest) && (
              <Body className="tiketDetailsBody">
                <div className="cardContainer">
                  <Label>Special Request</Label>
                  <div className="cardItem">
                    <div>
                      <Paragraph
                        className="text"
                        style={{ color: "#18181899" }}
                      >
                        {serviceDetails.specialRequest}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </Body>
            )}
            {shouldShowWokers && (
              <Footer style={{ padding: "12px 24px 0 24px" }}>
                <Label>Workers</Label>
                {isVisible && (
                  <Button
                    onClick={() =>
                      dispatch(
                        openWorkersForm(
                          serviceDetails.id,
                          serviceDetails.workers
                        )
                      )
                    }
                    className="editWorkersButton"
                  >
                    Edit Workers
                  </Button>
                )}
                <div className="workersList">
                  {serviceDetails.workers.map(worker => (
                    <WorkerItem {...worker} key={worker.id} />
                  ))}
                </div>
              </Footer>
            )}
            {status && (
              <Footer style={{ padding: "12px 24px 0 24px" }}>
                <div className="cardContainer">
                  <Label>Ticket Information</Label>
                  <div className="cardItem">
                    <div>
                      <Paragraph className="boldText">
                        ID : 6918273873
                      </Paragraph>
                      <Paragraph
                        className="text"
                        style={{ color: "#18181899" }}
                      >
                        Order placed : 07/07/2021
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </Footer>
            )}
            {shouldShowComments && (
              <Footer style={{ padding: "12px 24px 0 24px" }}>
                <div className="cardContainer">
                  <Label>Comments</Label>
                  <div className="commentsList">
                    {serviceComments.map(comment => (
                      <ServiceComment
                        serviceComment={comment}
                        key={comment.id}
                        deleteComment={() => removeComment(comment)}
                      />
                    ))}
                  </div>
                  <div className="createComment">
                    <TextFieldWithButton
                      id="comment"
                      name="comment"
                      type="text"
                      value={values.comment}
                      touched={touched.comment}
                      error={errors.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onClick={createServiceCommentHandler}
                      disabled={values.comment === "" ? true : false}
                    />
                  </div>
                </div>
              </Footer>
            )}
          </Card>
        </TiketDetailsStyles>
      )}

      {tiketDetailsModalType && (
        <CentralModal
          className="estimationCard"
          closeModal={() => {
            if (
              location.pathname === "/services" ||
              location.pathname === "/employee-schedule"
            ) {
              dispatch(setServicesTiketDetailsModalType(undefined));
              return;
            }
            dispatch(setTiketDetailsModalType(undefined));
          }}
        >
          <Card>
            {tiketDetailsModalType === tiketDetailsModalTypeConsts.ETA_FORM && (
              <EtaForm
                updateEstimationDate={changeETAHandler}
                initialDateValue={serviceDetails.eta}
                closeModal={() => {
                  if (
                    location.pathname === "/services" ||
                    location.pathname === "/employee-schedule"
                  ) {
                    dispatch(setServicesTiketDetailsModalType(undefined));
                    return;
                  }
                  dispatch(setTiketDetailsModalType(undefined));
                }}
              />
            )}
            {tiketDetailsModalType ===
              tiketDetailsModalTypeConsts.BOOKED_FOR_FORM && (
              <BookedForForm
                updateBookigDate={changeBookedForHandler}
                initialDateValue={serviceDetails.time}
                closeModal={() => dispatch(setTiketDetailsModalType(undefined))}
              />
            )}
          </Card>
        </CentralModal>
      )}
    </>
  );
};

export default TiketDetails;
