// Domain Models
import { Permission } from ".";

// Utils
import { isEmpty } from "../../../utils";

export class Role {
  constructor(role = {}) {
    this.id = role.id;
    this.name = role.name;
    this.description = role.description;
    this.permissions = !isEmpty(role.permissions)
      ? role.permissions.map(permission => new Permission(permission))
      : [];
  }

  generateRoleForm() {
    return {
      name: this.name,
      description: this.description,
      permissions: this.permissions.map(permission => parseInt(permission.id))
    };
  }
}
