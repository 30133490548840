// Domain Models
import { Driver, Truck, Trailer, Worker, Location, Review } from ".";

// Utils
import { isEmpty } from "../../../utils";

export class Service {
  constructor(service = {}) {
    this.id = service.id;
    this.serviceTypeId = service.service_type_id;
    this.mobility = service.mobility;
    this.truckId = service.truck_id;
    this.trailerId = service.trailer_id;
    this.status = service.status;
    this.time = service.time;
    this.eta = service.eta;
    this.isSeen = service.is_seen;
    this.newMessagesCount = service.new_messages_count;
    this.phoneNumber = service.phone_number;
    this.specialRequest = service.special_request;
    this.driverId = service.driver_id;
    this.creatorId = service.creator_id;
    this.fleetId = service.fleet_id;
    this.branchId = service.branch_id;
    this.businessId = service.business_id;
    this.declineReason = service.decline_reason;
    this.createdAt = service.created_at;
    this.updatedAt = service.updated_at;
    this.system_service_description = service.system_service_description;
    this.driver = !isEmpty(service.driver)
      ? new Driver(service.driver)
      : undefined;
    this.serviceTypeKey = service.service_type_key;
    this.truck = !isEmpty(service.truck) ? new Truck(service.truck) : undefined;
    this.trailer = !isEmpty(service.trailer)
      ? new Trailer(service.trailer)
      : undefined;
    this.workers = !isEmpty(service.workers)
      ? service.workers.map(worker => new Worker(worker))
      : [];
    this.location = !isEmpty(service.location)
      ? new Location(service.location)
      : undefined;
    this.review = !isEmpty(service.review)
      ? new Review(service.review)
      : undefined;
  }
}
