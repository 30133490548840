import { Heading2 } from "../../../../components/typography";
import { Body, Card, CentralModal, Header } from "../../../../components/UI";
import { Close } from "../../../../SVGS";
import AddExternalServiceForm from "../AddExternalServiceForm/AddExternalServiceForm";
import * as Styled from "./AddExternalServiceModal.styles";

export default function AddExternalServiceModal({ closeModal }) {
  return (
    <CentralModal className="modalCard-externalService" closeModal={closeModal}>
      <Card className="card">
        <Styled.AddExternalService>
          <Header className="modalHeader">
            <Heading2>ADD EXTERNAL SERVICE</Heading2>
            <Close onClick={closeModal} />
          </Header>
          <Body>
            <AddExternalServiceForm closeModal={closeModal} />
          </Body>
        </Styled.AddExternalService>
      </Card>
    </CentralModal>
  );
}
