import { Label } from "../../../../components/typography";
import {
  Button,
  DatePicker,
  Select,
  Textarea,
  TimePicker
} from "../../../../components/UI";
import * as buttonDesignType from "../../../../builders/buttonDesign.types";
import { useExternalServiceForm } from "./hooks/useExternalServiceForm";
import * as Styled from "./AddExternalServiceForm.styles";
import useDropdownOptions from "./hooks/useDropdownOptions";

export default function AddExternalServiceForm({ closeModal }) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleChangeDate,
    handleChangeTime,
    isSubmitting
  } = useExternalServiceForm(closeModal);

  const dropdownOptions = useDropdownOptions();

  return (
    <form onSubmit={handleSubmit}>
      <Label>Service type</Label>
      <Select
        options={dropdownOptions}
        value={values.service_type_id}
        onChange={option => setFieldValue("service_type_id", option[0]?.id)}
        placeholder="Select service type"
        touched={touched.service_type_id}
        error={errors.service_type_id}
      />

      <Styled.InputsWrapper>
        <Styled.InputWrapper>
          <Label>Booked for date</Label>
          <DatePicker
            value={values.time}
            name="time"
            onChange={handleChangeDate}
            error={errors.time}
            touched={touched.time}
            minDate={new Date()}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <Label>Booked for time</Label>
          <TimePicker
            value={values.time ? new Date(values.time) : null}
            handleChange={time => handleChangeTime("time", time)}
            placeholderText="Choose time"
            name="time"
            error={errors.time}
            touched={touched.time}
            dateFormat="h:mm aa"
          />
        </Styled.InputWrapper>
      </Styled.InputsWrapper>
      <Styled.InputsWrapper>
        <Styled.InputWrapper>
          <Label>ETA date</Label>
          <DatePicker
            value={values.eta}
            onChange={handleChangeDate}
            error={errors.eta}
            touched={touched.eta}
            name="eta"
            minDate={values.time || new Date()}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <Label>ETA time</Label>
          <TimePicker
            value={values.eta ? new Date(values.eta) : null}
            handleChange={time => handleChangeTime("eta", time)}
            placeholderText="Choose time"
            error={errors.eta}
            touched={touched.eta}
            dateFormat="h:mm aa"
          />
        </Styled.InputWrapper>
      </Styled.InputsWrapper>

      <Label>Service Description</Label>
      <Textarea
        name="system_service_description"
        placeholder="Describe this service"
        onChange={handleChange}
        value={values.system_service_description}
        error={errors.system_service_description}
        touched={touched.system_service_description}
      />

      <Styled.ButtonContainer>
        <Button
          type="submit"
          buttonDesignType={buttonDesignType.PRIMARY}
          disabled={isSubmitting}
        >
          Add External Service
        </Button>
      </Styled.ButtonContainer>
    </form>
  );
}
