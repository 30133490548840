import styled from "styled-components";

export const InputsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const InputWrapper = styled.div`
  flex: 1;
`;

export const ButtonContainer = styled.div`
  text-align: right;

  & button:disabled {
    color: white;
    background: #6a7a9d;
    opacity: 1;
  }
`;
